import { IconButton, InputAdornment } from '@mui/material';
import './FormInput.css';
import React, { useState }  from "react";
import {Col, Row} from "react-bootstrap";
import { VisibilityOff, Visibility } from '@mui/icons-material';

export type RawFormInputProps = {
	type? : string, 
	name? : string, 
	onChange? : React.ChangeEventHandler<HTMLInputElement>, 
	value? : string | number, 
	pattern? : string, 
	minLength? : number, 
	maxLength? : number, 
	placeholder? : string, 
	readOnly? :boolean, 
	disabled? :boolean,
	required? :boolean, 
	step? : number,
	autoComplete? : React.HTMLInputAutoCompleteAttribute | undefined
}

export function formInputProps ({type, name, onChange, value, pattern, minLength = 0, maxLength = 255, placeholder = "", 
								readOnly = false, disabled = false, required = true, step, autoComplete = undefined} : RawFormInputProps) : RawFormInputProps{
	return {
		type, 
		name, 
		onChange, 
		value, 
		pattern, 
		minLength, 
		maxLength, 
		placeholder,
		readOnly, 
		disabled, 
		required,
		step,
		autoComplete
	};
}

interface FormInputProps {
	inputProps? : RawFormInputProps,
	label : React.ReactNode
  showOpenPasswordButton?: boolean
}

const FormInput = ({inputProps = {}, label = "", showOpenPasswordButton = false} : FormInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
	return (
		<Row 
			style={{
				display:"flex", 
				verticalAlign:"middle"
			}}
      >
			<Col 	
				md ={6}
				className = {`formInputLabel`}
			>
				{label}		
			</Col>
			<Col 	
				md ={6}
				className = {`formInputContainer`}
				>
				<input 	
					className={`formInput`}
					{ ...inputProps}
          type={showOpenPasswordButton && showPassword  ? 'text':inputProps.type}
        />
          {showOpenPasswordButton &&
            <IconButton
              className='formInputShowHideButton'
              aria-label={
                showPassword ? 'hide the password' : 'display the password'
              }
              onClick={()=>setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
              onMouseUp={e => e.preventDefault()}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
			</Col>
		</Row>
	);
};

export default FormInput;