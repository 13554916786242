import React, { useCallback, useMemo, useState } from "react";
import QueryService from "../../../../services/queryService";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { ContentCopy, Download } from "@mui/icons-material";
import config from "../../../../config/config";
import { useClipboard } from "use-clipboard-copy";
import styled from "styled-components";
import contractText from "./contractText";
import propTypes from "prop-types";
import staticRoutes from "../../../../config/staticRoutes";

const ContractHeading = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 1rem;
  font-size: larger;
`;
const ContractTitle = styled.div`
  font-size: x-large;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;
const ContractSubtitle = styled.h4`
  text-align: center;
  margin-bottom: 1rem;
`;

const ContractParagraph = styled(Typography)`
  font-size: larger !important;
  text-indent: 4%;
  text-align: justify !important;
`;
const ContractSectionHeader = styled.h4`
  margin: 1rem 0;
  text-align: center;
`;

const DownloadContractButton = () => {
  const queryService = useMemo(() => new QueryService(), []);
  const downloadFileName =
    "Публичный договор на продажу проездных документов по безналичному расчёту";
  const onButtonClick = useCallback(
    () =>
      queryService.getFileFromServer(
        null,
        `${config.env.staticBase}${staticRoutes.publicContract}`,
        downloadFileName
      ),
    [queryService]
  );
  return (
    <Tooltip title={"Скачать публичный договор"} placement="top">
      <IconButton
        sx={{
          position: "absolute",
          float: "right",
        }}
        color="success"
        onClick={onButtonClick}
      >
        <Download />
      </IconButton>
    </Tooltip>
  );
};

const PublicContractView = ({
  onCopyRequisites,
}: {
  onCopyRequisites: () => void;
}) => {
  const defaultTooltipTitle = "Скопировать реквизиты в буфер обмена";
  const [toolTipTitle, setToolTipTitle] = useState(defaultTooltipTitle);
  const clipboard = useClipboard();

  const copyRequisites = () => {
    clipboard.copy(config.requisites.forCopying || config.requisites.element);
    setToolTipTitle("Реквизиты скопированы в буфер обмена");
    onCopyRequisites();
  };
  return (
    <>
      <>
        <ContractHeading>
          УТВЕРЖДЕНО
          <br />
          Приказ генерального <br />
          директора государственного <br />
          предприятия «Минсктранс»
          <br />
          {config.publicContract.dateFrom} № {config.publicContract.number}
        </ContractHeading>
        <ContractTitle>
          ПУБЛИЧНЫЙ ДОГОВОР
          <DownloadContractButton />
        </ContractTitle>
        <ContractSubtitle>
          на продажу проездных документов по безналичному расчёту
        </ContractSubtitle>

        <ContractParagraph>
          Коммунальное транспортное унитарное предприятие (Государственное
          предприятие) «Минсктранс», именуемое в дальнейшем «Продавец», в лице
          директора филиала «Агентство Минсктранс» Сергея Александровича
          Ласицкого, действующего на основании доверенности от 16.12.2024 № 69,
          публикует настоящий публичный договор на продажу проездных документов
          по безналичному расчету (далее – договор) в адрес неопределенного
          круга юридических лиц или индивидуальных предпринимателей, именуемые в
          дальнейшем «Покупатель», каждый в отдельности именуемый Сторона, а
          вместе – Стороны
        </ContractParagraph>

        {/* --------------------(1...8)------------------------- */}
        {contractText.sections.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            <ContractSectionHeader>{section.header}</ContractSectionHeader>
            {section.paragraphs.map((paragraph, index) => (
              <ContractParagraph key={(sectionIndex + 1) * 100 + index}>
                {paragraph}
              </ContractParagraph>
            ))}
          </React.Fragment>
        ))}
      </>
      {/* --------------------9------------------------------- */}
      <ContractSectionHeader>9. РЕКВИЗИТЫ ПРОДАВЦА</ContractSectionHeader>
      <ContractParagraph>
        9.1. Коммунальное транспортное унитарное предприятие «Минсктранс»
        (Государственное предприятие «Минсктранс»).
      </ContractParagraph>

      <ContractParagraph>
        {config.requisites.element}
        <Tooltip
          title={toolTipTitle}
          placement="top"
          onMouseEnter={() => setToolTipTitle(defaultTooltipTitle)}
        >
          <IconButton onClick={copyRequisites}>
            <ContentCopy color="success" />
          </IconButton>
        </Tooltip>
      </ContractParagraph>
    </>
  );
};
PublicContractView.propTypes = {
  onCopyRequisites: propTypes.func,
};

export default PublicContractView;
