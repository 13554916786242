import React, {useState}  from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {alertStates, useAlert} from "../../../states/useAlert";
import {FormInput, formInputProps, AlertChoise, SubmitCancelForm, BasicModal} from '../../../Components';
import {Box} from "@mui/material";
import {passwordStrength} from 'check-password-strength';
import {selectPasswordChangeToken} from "../../../features/passwordChange/passwordChangeSlice";
import {userApi} from "../../../api";
import "./PasswordChangeForm.css";
import { useAppSelector } from "../../../app/hooks/hooks";

const modalDefault = () =>  ({state : false, header : "", mainText:"", onClose : ()=>{}});
const PasswordChangeForm = ()=> {
	const passChangeToken = useAppSelector(selectPasswordChangeToken);
	const navigate = useNavigate();
	const {alertStatus, setAlertStatus} = useAlert();
	const {alertStatus : passwordStrengthAlert, setAlertStatus : setPasswordStrengthAlert} = useAlert();
	const [password, setPassword] = useState({
		password : "", 
		passwordRepeat:""
	});
	const [changePassword, changePassResult] = userApi.useLazyChangePasswordQuery();
	const modalPassChangeSuccess = React.useMemo( ()=>({ 
		state : true, 
		header : "Пароль успешно изменен!", 
		mainText:"Закройте это окно для перехода к авторизации", 
		onClose : ()=>{ 
			setModal(modalDefault); 
			navigate("/login");
		},
	}),[navigate]); 
	const modalPassChangeError= React.useMemo( ()=>({ 
		state : true, 
		header : "Ошибка!", 
		mainText:	`Время действия кода изменения пароля истекло,
					 пройдите процедуру заново`, 
		onClose : ()=>{ 
			setModal(modalDefault); 
			navigate("/login");
		},
	}),[navigate]);
	const [modal, setModal] = React.useState(modalDefault);
	if(!passChangeToken){
		return(
			<Navigate to="/notFound" replace={true} />
		);
	}

	const submitForm = (e : React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setAlertStatus(false);
		setPasswordStrengthAlert(false);
		if(password.password !== password.passwordRepeat){
			setAlertStatus(true,  alertStates.errorAlert, "Ошибка! Пароли не совпадают");
			return;
		}		
		if(passwordStrength(password.password).value !== 'Strong'){
			setPasswordStrengthAlert(
				true, 
				alertStates.errorAlert, 
				"Ошибка! Слишком простой пароль. Пароль должен иметь длину не менее 10 символов, содержать хотя бы 1 спецсимвол, большую букву, маленькую букву и цифру. Пароль должен содержать только символы английского алфавита, спецсимволы(@,!,$,%,^ и др.) и цифры."
			);
			return;
		}
		changePassword({newPassword : password.password}).unwrap()
		.then(()=>{
			setModal(modalPassChangeSuccess);
			sessionStorage.clear();
		})
		.catch(error => {
			const {status, message} = error;
			setAlertStatus(true, alertStates.errorAlert, message);
			console.log(error);
			if (status === 401){
				setModal(modalPassChangeError);
				sessionStorage.clear();
			}
		});
	};

	const cancelForm = (e : React.FormEvent<HTMLFormElement>)=>{
		e.preventDefault();
		sessionStorage.clear();
		navigate("/", {replace : true});
	};

	const handleChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
		const {name, value} = e.target;
		if (name === "password" && passwordStrength(value).value !== 'Strong'){
			setPasswordStrengthAlert(true, alertStates.errorAlert,"Слишком простой пароль. Пароль должен иметь длину не менее 10 символов, содержать хотя бы 1 спецсимвол, большую букву, маленькую букву и цифру. Пароль должен содержать только символы английского алфавита, спецсимволы(@,!,$,%,^ и др.) и цифры.");
		}else if (name === "password"){
			setPasswordStrengthAlert( false);
		}
		if( (name === "password" && value === password.passwordRepeat) ||
			(name === "passwordRepeat" && value === password.password) ||
			value === ""){
			setAlertStatus( false);
		}else if ( (((name === "password" && value !== password.passwordRepeat &&  (password.passwordRepeat !== "")) ||
					(name === "passwordRepeat" && value !== password.password &&  password.password !== "")) 
					&& value !== "")){
			setAlertStatus(true, alertStates.passRepeatAlert, "Введенные пароли должны совпадать");
		}
		setPassword({...password,[name]:value});
	};

	return (
		<form 
			onSubmit={submitForm} 
			onReset={cancelForm}  
			id = "passChangeForm"
			>
			<div className="row">
				<div className="col-md-12 text-center">
					<h1 className='passChangeHeader'>
						Изменение пароля
					</h1>
					<p className='passChangeText'> 
						Введите новый пароль 
					</p>
					<Box sx={{width:"50%", margin:"auto"}}>			
						<FormInput 	
							label={"Пароль"} 
							inputProps={ 
								formInputProps({
									type : "password",
									name : "password",
									onChange : handleChange,
									value : password.password,
									placeholder : "********",
									minLength : 10,
									autoComplete : 'new-password',
								})
							}
							/>
						<FormInput 	
							label={"Повторите пароль"} 
							inputProps={ 
								formInputProps({
									type : "password",
									name : "passwordRepeat",
									onChange : handleChange,
									value : password.passwordRepeat,
									placeholder : "********",
									minLength : 10,
									autoComplete : 'new-password',
								})
							}
							/>
						<Box sx={{marginTop:"0.5rem"}}>	
							<AlertChoise {...passwordStrengthAlert}/>
							<AlertChoise {...alertStatus}/>	
						</Box>
					</Box>
				</div>
			</div>
			<div className='row buttonsContainer'>
				<SubmitCancelForm 
					resetText='Отмена' 
					submitText='Изменить пароль'
					submitLoadingText={'Соединение с сервером...'}
					submitLoading = {changePassResult.isFetching}
					/>
			</div>
			<BasicModal 
				header = {modal.header} 
				mainText= {modal.mainText} 
				isOpen={modal.state} 
				onClose={modal.onClose}
				/>
		</form>
	);
};
export default PasswordChangeForm;